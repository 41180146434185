import Service, {inject as service} from "@ember/service";
import {task} from "ember-concurrency";
import {tracked} from "@glimmer/tracking";
import {action} from "@ember/object";

export default class BulkCreditService extends Service {
  @service ajax;
  @service notifications;
  @service globalVars;

  @tracked processedRows = [];
  @tracked validRows = [];
  @tracked invalidRows = [];
  @tracked preparedData = [];

  @action
  validateFile(file) {
    this.validateFileTask.perform(file);
  }

  @action
  creditPlayers() {
    this.creditPlayersTask.perform(this.validRows);
  }

  @action
  filterValidRows(rows) {
    this.validRows = rows.filter(row => {
      return row.errors.length < 1;
    })
  }

  @action filterInvalidRows(rows) {
    this.invalidRows = rows.filter(row => {
      return row.errors.length > 0;
    });
  }

  @action
  sortProcessedRows() {
    this.processedRows =  this.invalidRows.concat(this.validRows);
  }

  @action
  clearAll() {
    this.processedRows = [];
    this.validRows = [];
    this.invalidRows = [];
  }

  prepareDataToCreditTask(array) {
    array.map((row) => {
      this.preparedData.push({
        ...row.command
      })
    });
  }

  clear() {
    this.processedRows = [];
    this.validRows = [];
    this.invalidRows = [];
    this.invalidRows = [];
  }

  @task *validateFileTask(file) {
    const formData = new FormData();
    formData.append('bonusList', file);
    yield this.ajax.post(this.globalVars.url.admin_api + '/player-bulk-bonus/validate', {
      data: formData,
      processData: false,
      contentType: false
    }).then((response) => {
      this.filterValidRows(response);
      this.filterInvalidRows(response);
      this.sortProcessedRows()
      this.notifications.success('Your file processed successfully', {
        autoClear: true
      });
    }).catch((error) => {
      this.notifications.error('There was an error while processing the file: '+ error, {
        autoClear: true
      });
    });
  }
  @task *creditPlayersTask(array) {
    this.prepareDataToCreditTask(array);

    yield this.ajax.post(this.globalVars.url.admin_api + '/player-bulk-bonus/add', {
      data: this.preparedData
    }).then(() => {
      this.notifications.success('Bulk credit in process, once completed you will receive a summary email with the subject “Bulk bonus crediting completed“.', {
        autoClear: true
      });
      this.clear();
    }).catch((error) => {
      this.notifications.error('There was an error while crediting the players: '+ error, {
        autoClear: true
      });
    });
  }
}
